import React from 'react'

import { Card, NumBox, Line } from './style'

type StepType = {
  description: string;
}
interface IStepper {
  data: StepType[];
}

const Stepper = ({ data }: IStepper) => {
  return (
    <div>
      <Line>
        <div className='d-flex justify-content-xl-center'>
          <div>
            {data.map((step: StepType, index: number) => (
              <div className={`d-flex align-items-center card-container-${index + 1}`} key={step.description}>
                <NumBox className='d-flex justify-content-center align-items-center'>
                  <span className='fs-24 lh-30 fw-600 text-white'>{index + 1}</span>
                </NumBox>
                <Card className='d-flex align-items-center '>
                  <div>
                    <p className='fs-14 fs-lg-16 fs-xl-18 lh-18 lh-lg-20 lh-xl-23 fw-700 text-grayscale--500 my-2'>{step.description}</p>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Line>
    </div>
  )
}

export default Stepper

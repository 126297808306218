
import React from 'react'
import Faq from 'src/components/Faq/index'
import { white } from 'src/styles/colors'

// Styles
import { SectionWrapper } from './style'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FAQComponent = (props: Faq) => {
  return (
    <SectionWrapper>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'>
              Dúvidas frequentes sobre os cartões Inter
            </h3>
          </div>
          <Faq
            answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='px-0'
            searchBg={white}
          />
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent

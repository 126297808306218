import React from 'react'

// Components
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

// Assets
import ContentAdvantagesJSON from '../../assets/data/ContentAdvantages.json'

import { Section } from './style'

type ItemProps = {
  icon: string;
  title: string;
  description: string;
}

const AproveiteVantagens = () => {
  const width = useWidth(300)

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 text-md-center'>
              <span className='d-lg-block'>Aproveite as vantagens de negociar sua</span> dívida com o Inter
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--400 text-md-center'>
              <span className='d-lg-block'>Ajudamos você a negociar com autonomia e sem comprometer o orçamento. A melhor escolha</span> para o seu bolso está aqui.
            </p>
          </div>
          { width < WIDTH_MD
            ? (
              <div className='col-12 col-lg-6 offset-lg-1 col-xl-6 offset-xl-0'>
                <DefaultCarousel
                  sm={{ items: 1, partialVisibilityGutter: 40 }}
                  md={{ items: 1 }}
                  lg={{ items: 1 }}
                  xl={{ items: 1 }}
                >
                  { ContentAdvantagesJSON.map((item: ItemProps) => (
                    <div className='bg-white rounded-3 px-4 pt-4 pb-1 mb-3 mr-3 card-advantages' key={item.icon}>
                      <OrangeIcon size='MD' color='#6A6C72' icon={item.icon} />
                      <h3 className='fs-18 lh-20 text-grayscale--500 fw-600 mt-4'>{item.title}</h3>
                      <p className='fs-16 lh-19 text-grayscale--500'>{item.description}</p>
                    </div>
                      ))
                  }
                </DefaultCarousel>
              </div>
            )
            : (
              ContentAdvantagesJSON.map((item: ItemProps) => (
                <div className='col-6 col-lg-3 mt-md-3' key={item.description}>
                  <div className='bg-white rounded-3 px-4 pt-4 pb-1 mb-3 card-advantages'>
                    <OrangeIcon size='MD' color='#6A6C72' icon={item.icon} />
                    <h3 className='fs-18 lh-20 text-grayscale--500 fw-600 mt-4'>{item.title}</h3>
                    <p className='fs-16 lh-19 text-grayscale--500'>{item.description}</p>
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    </Section>
 )
}

export default AproveiteVantagens

import React from 'react'
import Img from 'gatsby-image'

// Hooks
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'

// Components
import Stepper from './Stepper/_index'

import stepperData from '../../assets/data/StepperData.json'

// Assets
import { Section, Link, Button, ImgSize } from './style'

type heroProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
  sendDatalayerEvent: Function;
}

const FacaSuaNegociacao = ({ setIsOpen, isMobile, oneLink, sendDatalayerEvent }: heroProps) => {
  const handleButtonClick = () => {
    setIsOpen(true)
    sendDatalayerEvent({
      section: 'dobra_3',
      element_action: 'click button',
      element_name: 'Negociar no Suoer App',
      section_name: 'Faça sua negociação de forma simples e rápida',
    })
  }

  const width = useWidth(300)
  const data = usePageQuery()
  const title = <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600'><span className='d-lg-block'>Faça sua negociação</span> <span className='d-lg-block'>de forma simples e </span> rápida</h2>

  return (
    <Section className='py-5 bg-white'>
      <div className='container'>
        <div className='row align-items-center'>
          <ImgSize className='col-12 col-md-6'>
            {width < WIDTH_MD && title}
            <Img fluid={data.facaSuaNegociacao.fluid} alt='Tela de celular mostrando a área de cartões do Super App Inter' />
          </ImgSize>
          <div className='col-12 col-md-6'>
            {width >= WIDTH_MD && title}
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-19 lh-xl-22 text-grayscale--500'>
              No Super App, você visualiza a sua fatura atrasada, confere o valor da dívida e realiza o pagamento com as melhores condições.
            </p>
            <Stepper data={stepperData} />
            {
            isMobile ? (
              <Link
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    element_action: 'click button',
                    element_name: 'Negociar no Suoer Apps',
                    section_name: 'Faça sua negociação de forma simples e rápida',
                    redirect_url: oneLink,
                  })
                }}
                href={oneLink}
              >Negociar no Super App
              </Link>
            ) : (
              <Button onClick={handleButtonClick}>
                Negociar no Super App
              </Button>
            )
          }
          </div>
        </div>
      </div>
    </Section>
 )
}

export default FacaSuaNegociacao

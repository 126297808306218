import React, { useEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD } from 'src/utils/breakpoints'

import { ContentHeader } from './style'
import { Button } from '@interco/inter-ui/components/Button'
import { initTheme, Theme } from '@interco/inter-ui'

type IModalDataLayerProps = {
  sendDatalayerEvent: Function;
}

const Header = ({ sendDatalayerEvent }: IModalDataLayerProps) => {
  const width = useWidth(300)
  const title = <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>Renegociação de dívidas sem burocracia!</h1>
  const url = 'https://negocie.bancointer.com.br/?utm_source=banco_inter_landing'

  useEffect(() => {
    initTheme(Theme.PF)
  }, [ ])

  return (
    <ContentHeader className='py-5 d-flex flex-column justify-content-center'>
      <div className='container'>
        <div className='row d-md-flex justify-content-md-between align-items-center'>
          <div className='col-12 col-md-6 order-md-last d-md-flex justify-content-md-end '>
            {width < WIDTH_MD && title}
            <picture className='picture_container'>
              <source
                srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/negocie-hero-1440/image.png'
                media='(min-width: 1440px)'
                type='image/webp'
              />
              <source
                srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/negocie-hero-1024/image.png'
                media='(min-width: 1024px)'
                type='image/png'
              />
              <img
                srcSet='https://central-imagens.bancointer.com.br/images-without-small-versions/negocie-hero-768-360/image.png'
                alt='Mulher sorrindo com o telefone em mãos utilizando o Super App'
                className='picture_container__image'
              />
            </picture>
          </div>
          <div className='col-12 col-md-6'>
            {width >= WIDTH_MD && title}
            <p className='fs-16 lh-19 fs-xl-18 lh-xl-22 text-grayscale--500 fw-400 mt-3'>
              Negocie a sua dívida do cartão de crédito Inter 100% online, com opções de parcelamento que cabem no seu bolso. É simples, rápido e seguro.
            </p>
            <Button
              as='a'
              href={url}
              title='Consultar Minhas Dívidas'
              target='blank'
              className='mt-2 p-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_1',
                  element_action: 'click button',
                  element_name: 'Consultar Minhas Dívidas',
                  redirect_url: url,
                  section_name: 'Desenrola Brasil: renegociação de dívida no Inter sem burocracia',
                })
              }}
              fullWidth
            >
              Consultar Minhas Dívidas
            </Button>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  min-height: 500px;
  display: flex;
  align-items: center;
  padding-top: 40px;
  background-color: ${grayscale[100]};

  @media ${device.tablet} {
    padding-top: 60px;
  }
  @media ${device.desktopLG} {
    padding-top: 80px;
  }
  @media ${device.desktopXL} {
    padding-top: 100px;
  }
`

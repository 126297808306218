import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${grayscale[100]};

  .card-advantages {
    min-height: 228px;

    @media ${device.tablet} {
      min-height: 287px;
    }
  }

  .react-multiple-carousel {
    &__arrow--right {
      right: 0;
    }
    &__arrow--left {
      left: -9px;
    }
  }


  .image_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

    @media ${device.tablet}{
      justify-content: flex-end;
    }
    
    &__image {
      width: 100%;
      height: 100%;

      max-width: 312px;
      max-height: 312px;
      
      @media ${device.tablet}{
        max-width: 331px;
        max-height: 444px;
      }
      @media ${device.desktopLG}{
        max-width: 358px;
        max-height: 479px;
      }
      @media ${device.desktopXL}{
        max-width: 358px;
        max-height: 479px;
      }
    }
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;

  &:focus {
    outline: none;
  }

  @media ${device.desktopLG} {
    margin-top: 33px;
  }
  @media ${device.desktopXL} {
    margin-top: 27px;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media (min-width: ${device.desktopLG}) {
    margin-top: 33px;
  }
  @media (min-width: ${device.desktopXL}) {
    margin-top: 27px;
  }
`

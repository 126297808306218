import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentHeader = styled.section`
  position: relative;
  min-height: 638px;
  h1 {
    font-family: 'Sora';
  }

  @media ${device.tablet} {
    min-height: 391px;
  }

  @media ${device.desktopLG} {
    min-height: 359px;
  }

  @media ${device.desktopXL} {
    min-height: 648px;
  }

  a {
    height: 40px;

    @media ${device.desktopXL} {
      max-width: 429px!important;
    }
  }

  .picture_container {
    &__image {
      width: 310px;
      height: 311px;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;

      @media ${device.desktopLG}{
        width: 374px;
        height: 187px;
        justify-content: flex-end;
      }

      @media ${device.desktopXXL}{
        width: 395px;
        height: 396px;
      }
    }
  }
`

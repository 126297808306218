import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  .scroll {
    overflow: auto;
    white-space: nowrap;
    position: relative;

    @media ${device.tablet} {
    white-space: normal;
  }

    div {
      display: inline-block;
      box-shadow: rgba(0, 0, 0, 0.07) 0 0 5px 3px;
      border-radius: 8px;
      width: 150px;
      height: 82px;
      margin-right: 12px;
    }
  }
`
